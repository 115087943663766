@import "../../../assets/scss/variables";

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  flex: 1;
  background-image: url("../../../assets/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Porsche Next";

  .title {
    font-size: 104px;
    text-align: center;
    font-weight: 325;
    color: var(--text-primary-inverted);
    margin-bottom: 16px;
    line-height: 100%;
    max-width: 15ch;

    b {
      font-weight: 800;
    }
  }
  .subtitle {
    margin-bottom: 64px;
    font-family: "Poppins";
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-primary-inverted);
  }

  .login-button {
    display: flex;
    color-scheme: light dark !important;
    align-items: flex-start;
    justify-content: center;
    min-width: 54px;
    min-height: 54px;
    box-sizing: border-box;
    text-align: start;
    appearance: none;
    text-decoration: none;
    border: 2px solid rgb(251, 252, 255);
    border-radius: 4px;
    transform: translate3d(0px, 0px, 0px);
    background-color: rgb(251, 252, 255);
    color: rgb(1, 2, 5);
    font:
      400 1rem / calc(2.125ex + 6px) "Porsche Next",
      "Arial Narrow",
      Arial,
      "Heiti SC",
      SimHei,
      sans-serif;
    overflow-wrap: break-word;
    hyphens: auto;
    transition:
      background-color var(--p-transition-duration, 0.25s)
        cubic-bezier(0.25, 0.1, 0.25, 1),
      border-color var(--p-transition-duration, 0.25s)
        cubic-bezier(0.25, 0.1, 0.25, 1),
      color var(--p-transition-duration, 0.25s) cubic-bezier(0.25, 0.1, 0.25, 1);
    padding: 13px 26px;
    gap: 8px;
    cursor: pointer;
    margin: 0px;

    &:hover {
      background-color: rgb(175, 176, 179);
      border-color: rgb(175, 176, 179);
    }

    span {
      position: static;
      width: auto;
      height: auto;
      padding: 0px;
      margin: 0px;
      overflow: visible;
      clip: auto;
      white-space: normal;
      transition: opacity var(--p-transition-duration, 0.25s)
        cubic-bezier(0.25, 0.1, 0.25, 1);
    }
  }
}
[data-theme="light"] {
  // pages specific
  --landing-page-items-bg: #{$whiteWashed2};
  --landing-page-h2u-bg: #{$white};

  transition: all 750ms;
}
[data-theme="dark"] {
  // pages specific
  --landing-page-items-bg: #{$black};
  --landing-page-h2u-bg: #{$blackWashed};

  transition: all 750ms;
}
