.title {
  font-family: "Bebas Neue Pro Middle";
  font-weight: 600;
  font-size: 40px;
  line-height: 1em;
  color: var(--text-primary);
  margin-block: 0 10px;
}

.description {
  margin-block: 0 32px;
  color: var(--text-secondary);
  font-size: 18px;
}
