@import "../../../assets/scss/variables";

.trigger {
  padding: 12px 16px;
  background: var(--background);
  border: 1px solid var(--background-washed-2);
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  font-family: "Poppins";
  font-size: 14px;
  gap: 1rem;
}

.item {
  position: relative;
  display: flex;
  width: 100%;
  cursor: default;
  user-select: none;
  align-items: center;
  padding-block: 0.75rem;
  font-weight: 500;
  padding-inline: 2.5rem 3rem;
  font-size: 14px;
  outline: none;

  &:focus {
    background: var(--background-washed);
  }

  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}

.content {
  position: relative;
  z-index: 2000;
  max-height: 24rem;
  min-width: 8rem;
  overflow: hidden;
  border: 1px solid var(--background-washed-2);
  background: var(--background);
  color: var(--text-primary);
}

.item-label {
  padding-inline: 2.5rem;
  padding-block: 0.5rem;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-secondary);
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
  position: relative;
  font-family: "Poppins";
  color: var(--text-primary);
}

.label {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
  font-size: 0.875rem;
  font-weight: 500;

  &:has(p.description) {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--background-washed-2);
}

.item-indicator {
  position: absolute;
  left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.description {
  font-family: "Poppins";
  font-size: $fontSmall;
  color: var(--text-secondary);
  margin: 0 0 8px;
}
