@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.section-wrapper {
  .section-nav {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    width: 100%;
    gap: 12px;
  }

  .search-wrapper {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .search-field {
      display: flex;
      align-items: center;
      background: inherit;
      border: none;
      width: 100%;
      color: var(--text-primary);
      font-family: "Poppins Regular";
      font-weight: 400;
      font-size: 14px;
      &:focus {
        outline: none;
      }
    }

    .search-icon {
      margin-right: 8px;
    }
  }

  .toggle-wrapper {
    @include flex-centered;
    gap: 1rem;
    border-right: 1px solid $color-disabled;
    padding-right: 0.5rem;

    p {
      margin: 0;
    }
  }
  .chart-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Bebas Neue Pro SemiExpanded Bold";
    font-size: $fontXLarge;
    height: 100%;
    background: transparent;
    color: var(--text-primary);
    cursor: pointer;
  }
}
