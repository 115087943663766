@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.section-title {
  font-family: "Bebas Neue Pro Middle";
  font-weight: 600;
  font-size: 40px;
  line-height: 1em;
  color: var(--text-primary);
  margin-block: 0 10px;
}

.section-description {
  margin-block: 0 32px;
  color: var(--text-secondary);
  font-size: 18px;
}

.section-wrapper {
  .section-nav {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;
    gap: 12px;
  }

  .search-wrapper {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .search-field {
      display: flex;
      align-items: center;
      background: inherit;
      border: none;
      width: 100%;
      color: var(--text-primary);
      font-family: "Poppins Regular";
      font-weight: 400;
      font-size: 14px;
      &:focus {
        outline: none;
      }
    }

    .search-icon {
      margin-right: 8px;
    }
  }

  .create-button {
    .create-button-icon {
      height: 1em;
    }
  }

  .toggle-wrapper {
    @include flex-centered;
    gap: 1rem;
    border-right: 1px solid $color-disabled;
    padding-right: 0.5rem;

    p {
      margin: 0;
    }
  }
}

.modal-screen {
  width: 640px;
  height: 46rem;
  display: flex;
  overflow: scroll;
}
