@import "../../assets/scss/variables";
@import "../../assets/scss/fonts";

.operational-status {
  font-size: $fontLarge;
  font-family: "Bebas Neue Pro";
  font-weight: $weightMedium;
}
.not-operational {
  color: red;
}

.operational {
  color: green;
}

.separator {
  border-left: 1px solid var(--header-separator-bg);
  height: 24px;
  margin: 0 12px;
}
