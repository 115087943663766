.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--button-height);
  font-size: var(--button-font-size);
  padding: var(--button-padding-y) var(--button-padding-x);
  gap: 0.2em;
  position: relative;
  font-family: "Bebas Neue Pro SemiExpanded";
  will-change: transform;
  cursor: pointer;
  background-color: var(--background-inverted);
  border: 1px solid var(--text-primary);
  color: var(--text-primary-inverted);
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.25s;
  box-shadow:
    0 0 #0000,
    0 0 #0000,
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px -1px rgba(0, 0, 0, 0.1);
  font-weight: 700;

  &:not([data-variant="ghost"]) {
    &[data-size="small"] {
      --button-height: 20px;
      --button-font-size: 14px;
      --button-padding-y: 4px;
      --button-padding-x: 8px;
    }
    &[data-size="medium"] {
      --button-height: 32px;
      --button-font-size: 16px;
      --button-padding-y: 2px;
      --button-padding-x: 12px;
    }
    &[data-size="large"] {
      --button-height: 48px;
      --button-font-size: 20px;
    }
  }

  &[data-size="small"] {
    --button-padding-y: 4px;
    --button-padding-x: 8px;
  }
  &[data-size="medium"] {
    --button-padding-y: 6px;
    --button-padding-x: 12px;
  }
  &[data-size="large"] {
    --button-padding-y: 8px;
    --button-padding-x: 16px;
  }

  &[data-accent-color="primary"] {
    --button-color-primary: var(--text-primary-inverted);
    --button-background-primary: var(--background-inverted);
    --button-background-hover-primary: var(--state-hover-inverted);
    --button-background-disabled-primary: var(
      --state-disabled-background-inverted
    );

    --button-color-ghost: var(--text-primary);
    --button-background-hover-ghost: var(--state-hover);
    --button-background-disabled-ghost: var(--state-disabled-background);
  }

  &[data-accent-color="danger"] {
    --button-color-primary: var(--text-primary-inverted);
    --button-background-primary: var(--text-danger);
    // TODO: These should also be global colors
    --button-background-hover-primary: color-mix(
      in srgb,
      var(--text-danger) 80%,
      #000 20%
    );
    --button-background-disabled-primary: color-mix(
      in srgb,
      var(--text-danger) 50%,
      #000 50%
    );

    --button-color-ghost: var(--text-danger);
    --button-background-hover-ghost: color-mix(
      in srgb,
      var(--text-danger) 20%,
      transparent 80%
    );
    --button-background-disabled-ghost: color-mix(
      in srgb,
      var(--text-danger) 50%,
      var(--background) 50%
    );
  }

  &[data-variant="primary"] {
    background-color: var(--button-background-primary);
    border: 1px solid var(--button-background-primary);
    color: var(--button-color-primary);

    @media (hover: hover) {
      &:hover {
        background-color: var(--button-background-hover-primary);
        border-color: var(--button-background-hover-primary);
      }
    }

    &:disabled,
    &[data-disabled] {
      cursor: not-allowed;
      background-color: var(--button-background-disabled-primary);
      box-shadow: none;
      border-color: var(--button-background-disabled-primary);
    }
  }

  &[data-variant="ghost"] {
    background-color: transparent;
    color: var(--button-color-ghost);
    border: none;
    box-shadow: none;
    /* Make sure that the height is not stretched in a Flex/Grid layout */
    height: fit-content;

    @media (hover: hover) {
      &:hover {
        background-color: var(--button-background-hover-ghost);
        border-color: var(--button-background-hover-ghost);
      }
    }

    &:disabled,
    &[data-disabled] {
      cursor: not-allowed;
      background-color: var(--button-background-disabled-ghost);
      border-color: var(--button-background-disabled-ghost);
      opacity: 0.5;
    }
  }
}
