@import "../../../assets/scss/variables";

.input-with-label {
  width: 100%;

  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 1;
    font-size: 0.875rem;
    font-weight: 500;
    gap: 4px;
    color: var(--text-primary);
    font-family: "Poppins";

    .tooltip-text {
      margin: 0;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-family: "Poppins";
    }

    .tooltip {
      background-color: var(--tooltip-bg);
      border: 1px solid var(--background-inverted);
      color: var(--text-primary);
      border-radius: 0px;
      padding: 15px 12px;
      font-family: "Poppins";
      font-size: $fontXSmall;
      font-weight: 400;
      max-width: 280px;
    }

    &:not(:has(+ .description)):has(+ .input-wrapper) {
      margin-bottom: 8px;
    }
  }

  &.checkbox-wrapper {
    display: flex;
    flex-direction: row;
    font-size: $fontSmall;
    padding-bottom: 10px;
    padding-top: 10px;
    gap: 4px;
  }
}

.date-wrapper {
  width: 100%;
  color: black;
  font-family: "Poppins";

  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 1;
    font-size: 0.875rem;
    font-weight: 500;
    gap: 4px;
    width: 100%;

    .tooltip-text {
      margin: 0;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .tooltip {
      background-color: var(--tooltip-bg);
      border: 1px solid var(--background-inverted);
      color: var(--text-primary);
      border-radius: 0px;
      padding: 15px 12px;
      font-family: "Poppins";
      font-size: $fontXSmall;
      font-weight: 400;
      max-width: 280px;
    }

    &:not(:has(+ .description)):has(+ .input-wrapper) {
      margin-bottom: 8px;
    }
  }

  // input {
  //   &:focus-within {
  //     border: 1.5px solid var(--background-inverted);
  //   }
  // }
}

.datePicker {
  button {
    background: var(--background);
    &::after {
      background-color: var(--text-primary);
      color: var(--background);
      font-size: $fontMedium;
    }
  }
}

.date-input {
  appearance: none;
  border: 1px solid var(--background-washed-2);
  padding: 0.5rem 2rem 0.5rem 1rem;
  background-color: var(--background);
  color: var(--text-primary);
  -webkit-appearance: none;
  height: 48px;
  font-size: 14px;
  font-family: "Poppins Regular";
  font-weight: $weightNormal;
  font-size: $fontSmall;

  &:focus {
    border: 1.5px solid var(--background-inverted);
  }
}

.date-input-wrapper {
  position: relative;

  .calendar-icon {
    position: absolute;
    right: 1rem;
    top: 0.9rem;
    cursor: pointer;
  }
}
.hidden {
  display: none;
}

.user-icon {
  padding-left: 16px;
}

.icon {
  padding-left: 16px;
  height: 1em;
}

.eye-icon {
  padding-right: 16px;
  &:hover {
    cursor: pointer;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  border: 1px solid var(--background-washed-2);
  background-color: var(--background);

  &.disabled {
    border: 1px solid #d5d5d5;
    background: white;
  }

  &.dark {
    border: 1px solid #3c3c3c;
    background: black;

    &:focus-within {
      border: 1.5px solid white;
    }

    &.disabled {
      border: 1px solid #1d1d1d;
      background: #1d1d1d;
    }
  }
}

.input {
  font-family: "Poppins";
  font-size: 14px;
  padding: 12px 16px;
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  color: var(--text-primary);

  &.dark {
    background: black;
    color: white;

    &:disabled {
      background: #1d1d1d;
      color: #565656;
    }
  }

  &:disabled {
    background: white;
    color: #949494;
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none !important;
  }

  &.checkbox {
    width: auto;
    height: auto;
  }

  &.error-state {
    border: 1px solid var(--text-danger);
  }
}

.description {
  font-family: "Poppins";
  font-size: $fontSmall;
  color: var(--text-secondary);
  margin: 0 0 8px;
}

.error {
  font-family: "Poppins";
  font-size: $fontSmall;
  color: var(--text-danger);
  margin: 0 0 8px;
}

input[type="number"],
input[type="range"] {
  touch-action: none; /* Allows panning and pinching, but not other interactions */
}
