.list-container {
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  gap: 0.5rem;
}

.remove-button {
  align-self: flex-end;
  height: 48px !important;
}

.add-btn {
  max-width: 300px;
  margin-inline-start: auto;
  margin-top: 1rem;
}
