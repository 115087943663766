.content {
  position: fixed;
  z-index: 1000;
}

.content:focus {
  outline: none;
}

.title {
  justify-self: flex-start;
  align-self: flex-start;
  width: 100%;
  color: var(--text-primary);
  font-family: "Bebas Neue Pro SemiExpanded Bold";
  font-size: 24px;
  align-self: flex-start;
  margin: 0;
  margin-bottom: 32px;
}
