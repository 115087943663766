@font-face {
  font-family: "Bebas Neue Pro";
  src: url("../../../public/fonts/Bebas Neue Pro.otf");
}

@font-face {
  font-family: "Bebas Neue Pro Book";
  src: url("../../../public/fonts/Bebas Neue Pro Book.otf");
}

@font-face {
  font-family: "Bebas Neue Pro Bold";
  src: url("../../../public/fonts/Bebas Neue Pro Bold.otf");
}

@font-face {
  font-family: "Bebas Neue Pro SemiExpanded";
  src: url("../../../public/fonts/Bebas\ Neue\ Pro\ SemiExpanded.otf");
}

@font-face {
  font-family: "Bebas Neue Pro SemiExpanded Bold";
  src: url("../../../public/fonts/Bebas\ Neue\ Pro\ SemiExpanded\ Bold.otf");
}

@font-face {
  font-family: "Bebas Neue Pro Expanded";
  src: url("../../../public/fonts/Bebas\ Neue\ Pro\ Expanded.otf");
}

@font-face {
  font-family: "Bebas Neue Pro Middle";
  src: url("../../../public/fonts/Bebas\ Neue\ Pro\ Middle.otf");
}

@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/Poppins.ttf");
}

@font-face {
  font-family: "Poppins Semibold";
  src: url("../../../public/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../../public/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../../public/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins Medium";
  src: url("../../../public/fonts/Poppins-Medium.ttf");
}
