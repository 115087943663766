@import "../../../assets/scss/variables";

.root {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: $fontSmall;
  line-height: 1.25em;
  gap: 32px;

  .step {
    flex: 1;
    padding-top: 1em;
    border-top: 4px solid
      color-mix(in srgb, var(--text-primary) 20%, transparent 80%);
    display: flex;
    flex-direction: column;

    .step-number {
      font-weight: 600;
      color: var(--text-secondary);
    }
    .step-label {
      font-weight: 500;
      color: var(--text-secondary);
    }

    &.active,
    &.done {
      border-top-color: var(--text-primary);
      .step-number {
        color: var(--text-primary);
      }
    }
  }
}
