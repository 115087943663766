.loader {
  @keyframes load {
    0% {
      transform: scale(1);
    }

    5% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.8);
    }

    95% {
      transform: scale(1);
    }

    100% {
      transform: scale(1);
    }
  }

  animation: load 1s infinite cubic-bezier(0.25, 0, 0.75, 1);
  transform: translateZ(0);
}
