@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

textarea {
  resize: none;
  font-family: "Poppins Regular";
  font-size: $fontSmall;
  font-weight: $weightNormal;
}

.input {
  padding: 0.5rem 1rem;
  height: 2.7rem;
  width: 100%;
  height: 48px;
  font-family: "Poppins Regular";
  font-size: $fontSmall;
  font-weight: $weightNormal;
  color: var(--text-primary);
}

input[type="date"] {
  font-family: "Poppins Regular";
  font-size: 14px;
}

.form1 {
  flex: 1;
  gap: 1.8rem;
  padding-block: 1rem;
  color: var(--text-primary);

  .disabled {
    background: $color-disabled;
    color: white;
    opacity: 0.4;
  }

  .btn {
    align-self: flex-end;
  }
}

.step-indicator {
  padding-block: 1rem;
}

.disabled {
  background: $color-disabled;
  color: white;
  opacity: 0.4;
}

.completed {
  border: 1px solid #f6f6f6;
}
.date-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.8rem;
  align-items: flex-end;
  position: relative;

  .input-error {
    border-color: red;
  }

  .err-msg {
    color: red;
    align-self: flex-start;
  }
}

.btn {
  align-self: flex-end;
}

.form3 {
  margin-top: 1rem;
  width: 100%;
  gap: 1.3rem;
  color: var(--text-primary);
  height: 100%;

  .active {
    background: var(--background);
    color: $white;
  }

  .disabled {
    background: $color-disabled;
    color: white;
    opacity: 0.4;
  }

  .desc-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;

    .label {
      margin-bottom: 4px;
      font-weight: $font-weight-bolded;
      font-family: "Poppins SemiBold";
      font-size: $fontXSmall;
    }

    .input {
      height: 7rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 1rem;
      color: var(--text-primary);
    }
  }

  .label-wrapper {
    @include flex-centered;
    font-weight: $font-weight-bolded;
    gap: 0.2rem;
  }

  .images-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;

    .images-container {
      margin-top: 0.6rem;
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;

      .images-scroll {
        gap: 1rem;
        height: 100%;
        display: flex;
        position: relative;
        padding: 0 2rem;

        .scroll-arrow-prev {
          height: 100%;
          @include flex-centered;
          align-items: flex-start;
          flex-direction: column;
          position: absolute;
          left: 0.7rem;
          z-index: 500;
          width: 3.5rem;
          // background:orange;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 38%,
            rgba(255, 255, 255, 0.887) 60%,
            rgba(255, 255, 255, 0.719) 74%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .scroll-arrow-next {
          height: 100%;
          @include flex-centered;
          align-items: flex-end;
          flex-direction: column;
          position: absolute;
          right: 0.8rem;
          z-index: 500;
          width: 3.5rem;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4220281862745098) 18%,
            rgba(255, 255, 255, 0.6657256652661064) 28%,
            rgba(255, 255, 255, 0.8449973739495799) 37%,
            rgba(255, 255, 255, 1) 51%
          );
        }
      }

      .image-box {
        @include flex-centered;
        flex-direction: column;
        width: 98px;
        height: 98px;
        background: #3c3c3c;
        position: relative;
        overflow: hidden;

        img:nth-child(1) {
          min-width: 98px;
          min-height: 98px;
          object-fit: cover;
        }

        .nft {
          position: absolute;
          top: 0.15rem;
          left: 0.15rem;
          background-color: $black;
          color: $white;
          font-family: "Poppins SemiBold";
          font-size: $fontSmall;
          font-weight: $weightSemiBold;
          padding: 11px, 8px, 10px, 8px;
          width: 32px;
          height: 16px;
          @include flex-centered;
        }

        .close-icon {
          position: absolute;
          top: 0.15rem;
          right: 0.15rem;
          background-color: #1d1d1d;
          border-radius: 50%;
          padding: 0.2rem;
          cursor: pointer;
          display: none;
        }
      }

      .add-box {
        font-size: 2rem;
        @include flex-centered;
        flex-direction: column;
        width: 98px;
        height: 98px;
        position: relative;
        // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border: 1px dashed var(--background-inverted);
        cursor: pointer;
        overflow: hidden;
        div {
          border: unset !important;
        }
        .add {
          position: absolute;
          cursor: pointer;
          @include flex-centered;
          flex-direction: column;

          p {
            font-family: "Poppins Regular";
            font-size: $fontTiny;
            font-weight: $weightNormal;
            margin: 0;
            color: $color-disabled;

            &:first-child {
              font-size: 24px;
              margin-bottom: -0.5rem;
            }
          }
        }

        .files-input {
          opacity: 0;
          width: 12rem;
          height: 15rem;
          cursor: pointer;
        }
      }
    }
  }

  .promo-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .add-box {
      font-size: 2rem;
      @include flex-centered;
      flex-direction: column;
      width: 17rem;
      height: 6.7rem;
      position: relative;
      // @include border-dashed;
      // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border: 1px dashed var(--background-inverted);
      cursor: pointer;
      overflow: hidden;
      margin-top: 0.5rem;
      div {
        border: unset !important;
      }
      .close-icon {
        position: absolute;
        top: 0.15rem;
        right: 0.15rem;
        background-color: #1d1d1d;
        border-radius: 50%;
        padding: 0.2rem;
        cursor: pointer;
        // display: none;
      }

      .add {
        position: absolute;
        cursor: pointer;
        text-align: center;
        @include flex-centered;
        flex-direction: column;

        p {
          font-family: "Poppins Regular";
          font-size: $fontTiny;
          font-weight: $weightNormal;
          margin: 0;
          color: $color-disabled;

          &:first-child {
            font-size: 24px;
            margin-bottom: -0.5rem;
          }
        }

        img {
          width: 230%;
          position: absolute;
        }
      }

      .disabled-input {
        p {
          color: $color-disabled;
          // opacity: 0.4;
        }
      }

      .files-input {
        opacity: 0;
        width: 18rem;
        height: 8rem;
        cursor: pointer;
        border: 1px dashed var(--background-inverted);
      }
    }

    .toggle-wrapper {
      @include flex-centered;
      gap: 1rem;
      margin-left: 0.7rem;
    }
  }

  .footer {
    color: var(--text-primary);
  }
}

.summary-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .summary-form {
    display: grid;
    background: var(--background-washed);
    width: 100%;
    flex: 1;
    gap: 1rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 24px;

    .img-grid {
      grid-column: 1/2;
      display: grid;
      grid-template-rows: 128px 128px 128px;
      grid-template-columns: 128px 128px;
      gap: 8px;
      overflow: scroll;

      .cover-img {
        grid-column: 1/3;
        width: 100%;
        overflow: hidden;
        display: flex;
        @include flex-centered;
        position: relative;

        img {
          width: 17rem;
        }

        .promo-sticker {
          position: absolute;
          top: 0.15rem;
          left: 0.15rem;
          // width:114px;
          height: 16px;
          background-color: $white;
          color: $black;
          font-family: "Poppins SemiBold";
          font-size: $fontSmall;
          font-weight: $weightSemiBold;
          padding: 3px;
          @include flex-centered;
        }
      }

      .img-main-box {
        @include flex-centered;
        flex-direction: column;
        width: 128px;
        height: 128px;
        background: #3c3c3c;
        position: relative;
        overflow: hidden;

        img:nth-child(1) {
          min-width: 128px;
          min-height: 128px;
          display: flex;
        }

        .nft {
          position: absolute;
          top: 0.15rem;
          left: 0.15rem;
          background-color: $black;
          color: $white;
          font-family: "Poppins SemiBold";
          font-size: $fontSmall;
          font-weight: $weightSemiBold;
          padding: 11px, 8px, 10px, 8px;
          width: 32px;
          height: 16px;
          @include flex-centered;
        }
      }

      .images-box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      .image-box {
        @include flex-centered;
        flex-direction: column;
        width: 128px;
        height: 128px;
        background: #3c3c3c;
        position: relative;
        overflow: hidden;

        img {
          min-width: 128px;
          min-height: 128px;
        }
      }

      .add-box {
        font-size: 1rem;
        @include flex-centered;
        flex-direction: column;
        width: 128px;
        height: 128px;
        position: relative;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        cursor: pointer;
        overflow: hidden;
        color: $black;
      }
    }

    .info-grid {
      display: flex;
      flex-direction: column;
      justify-items: flex-start;
      font-family: "Poppins";
      color: $black;

      .key {
        color: #949494;
        font-size: $fontXSmall;
        margin: 0;
      }

      .value {
        font-size: $fontXSmall;
        margin: 0;
        white-space: wrap;
        overflow: hidden;
        color: var(--text-primary);
      }

      .divider {
        background: #3c3c3c;
        height: 1px;
      }

      .key-value-wrapper {
        display: grid;
        grid-template-columns: 1fr 1.4fr;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;
        &:last-child {
        }

        button {
          font-family: inherit;
        }
      }

      .key-value-box-1 {
        grid-row: 1/2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid var(--state-active);
        width: 100%;
      }

      .key-value-box-2 {
        margin-top: 8px;
        grid-column: 1/2;
        grid-row: 2/3;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid var(--state-active);
      }

      .key-value-box-3 {
        margin-top: 8px;
        grid-column: 1/2;
        grid-row: 3/4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .key {
          align-self: flex-start;
        }
        .value {
          overflow-wrap: anywhere;
          height: 100%;
          max-height: 150px;
        }
        .show-overflow {
          overflow-y: scroll;
        }
        button {
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
        }
        *::-webkit-scrollbar {
          width: 4px;
          background-color: #3c3c3c;
        }
      }
    }
  }

  .footer {
    color: var(--text-primary);
  }

  .active {
    background: $black;
    color: $white;
  }

  .disabled {
    background: $color-disabled;
    color: white;
    opacity: 0.4;
  }
}

.success-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  height: 100%;

  .label {
    font-family: "Bebas Neue Pro SemiExpanded Bold";
    font-size: 24px;
    margin-bottom: 8px;
  }

  .msg {
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
    color: var(--popup-success-description);
    font-family: "Poppins";
    font-size: $fontSmall;
  }

  .btn {
    align-self: center;
    width: 100%;
  }

  .program-page {
    display: flex;
    font-weight: $font-weight-bolded;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      font-family: "Bebas Neue Pro SemiExpanded Bold";
      font-size: 20px;
      margin: 0;
    }
    img {
      transform: rotate(180deg);
      margin: 0;
      margin-left: 4px;
    }
  }
}

.confirm-screen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25rem;
  color: var(--text-primary);

  .label-wrapper {
    display: grid;
    grid-template-columns: 1fr 7fr;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
  }

  .label {
    font-size: $font-giant;
    font-weight: $font-weight-bolded;
    justify-self: center;
    margin: 0;
  }

  .arrow {
    cursor: pointer;
  }

  .disclaimer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 90%;
    font-family: "Poppins SemiBold";

    .heading {
      font-size: $font-normal;
      font-weight: $font-weight-bolded;
      margin: 0;
      margin-bottom: 8px;
    }
    .desc {
      font-family: "Poppins";
      font-size: $fontXSmall;
      color: var(--popup-success-description);
      margin: 0;
      margin-bottom: 8px;
    }
  }

  .btn {
    align-self: center;
    width: 100%;
    margin-top: 24px;
  }
}

.footer {
  padding-block-start: 16px;
}
