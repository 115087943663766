@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.layout {
  --tab-height: 40px;
  --bar-padding-y: 12px;
  --bar-background: var(--background);
  --bar-height: calc(var(--tab-height) + 2 * var(--bar-padding-y));

  .nav-container {
    position: fixed;
    z-index: 999;
    top: 88px;
    left: 0;
    right: 0;
    width: 100%;
    height: var(--bar-height);
    padding-block: var(--bar-padding-y);
    background-color: var(--bar-background);
    transition: all 750ms;
    border-top: 1px solid var(--background-washed);

    button:first-of-type {
      margin-inline-start: calc(-1 * var(--tab-inner-padding-x));
      padding-inline-start: 40px;
    }
    button:last-of-type {
      margin-inline-end: calc(-1 * var(--tab-inner-padding-x));
      padding-inline-end: 40px;
    }

    .link-button {
      margin: 0;
      display: inline-flex;
      align-items: center;
      .tab-icon {
        height: 1em;
        width: 1em;
        margin-right: 8px;
      }
    }
  }
  .admin-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: calc(var(--bar-height) + 48px) 80px 48px;
    width: 100%;
    // max-width: $maxSectionWidth;
    min-height: calc(
      100vh - 88px
    ); // TODO: 88px is the height of the top bar, should be a variable
    background: var(--background-washed);
    position: relative;
  }
}
