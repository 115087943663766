@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";

.nav-label {
  [data-theme="light"] {
    --nav-label-inactive-color: #{$lightGrey};
    --nav-label-hover-color: #{$grey};
    --nav-label-active: #949494;
    --nav-label-hover: #717070;
    --nav-label-disabled: #d5d5d5;
  }
  [data-theme="dark"] {
    --nav-label-inactive-color: #565656;
    --nav-label-hover-color: #{$lightGrey};
    --nav-label-active: #949494;
    --nav-label-hover: #d5d5d5;
    --nav-label-disabled: #565656;

    transition: all 750ms;
  }

  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  font-family: "Porsche Next";
  color: var(--text-primary);
  cursor: pointer;

  a {
    color: var(--text-primary);
  }
  .nav-text {
    display: flex;
    font-size: $fontXLarge;
    font-weight: $weightBold;

    align-items: center;
    color: var(--text-primary);
    img {
      margin-right: 4px;
    }
  }

  &:hover {
    color: var(--nav-label-hover-color);
  }
  &:active {
    color: var(--nav-label-active-color);
  }
  &:disabled {
    color: var(--nav-label-disabled-color);
  }

  .inactive {
    color: var(--nav-label-inactive-color);
    &:hover {
      color: var(--nav-label-hover-color);
    }
  }
  .active {
    color: var(--text-primary);
    &:hover {
      color: var(--text-primary);
    }
  }

  .nav-register {
    font-size: $fontSmall;
    font-weight: $weightBold;
    padding: 0 12px;
    border: 1px solid $whiteWashed;
    border-radius: 12px;
    padding: 10px 16px;
    margin-left: 12px;
  }
}
