@import "../../../assets/scss/variables";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--text-primary);

  .back-btn {
    font-size: $fontXLarge;
    gap: 0.5em;
  }
  & > *:only-child {
    margin-left: auto;
  }
}
