.base-tab-list {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  font-style: normal;
  border-bottom: 1px solid var(--background-washed);

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.base-tab-list-trigger {
  display: flex;
  align-items: center;
  background: none;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  user-select: none;
}

.base-tab-list-trigger-inner,
.base-tab-list-trigger-inner-hidden {
  display: flex;
  align-items: center;
  justify-content: center;
}

.base-tab-list-trigger-inner {
  position: absolute;
  font-weight: 700;

  :where(
      .base-tab-list-trigger[data-state="inactive"],
      .TabNavLink:not([data-active])
    )
    & {
    letter-spacing: 0em;
    word-spacing: 0em;
  }

  :where(.base-tab-list-trigger[data-state="active"], .TabNavLink[data-active])
    & {
    letter-spacing: -0.01em;
    word-spacing: 0em;
  }
}

.base-tab-list-trigger-inner-hidden {
  visibility: hidden;
  letter-spacing: -0.01em;
  word-spacing: 0em;
}

.base-tab-list-trigger {
  box-sizing: border-box;
  height: var(--tab-height);
  padding-left: var(--tab-inner-padding-x);
  padding-right: var(--tab-inner-padding-x);
}

.base-tab-list-trigger-inner,
.base-tab-list-trigger-inner-hidden {
  box-sizing: border-box;
  padding: var(--tab-inner-padding-y) var(--tab-inner-padding-x);
  border-radius: var(--tab-inner-border-radius);
}

/***************************************************************************************************
 *                                                                                                 *
 * VARIANTS                                                                                        *
 *                                                                                                 *
 ***************************************************************************************************/

.base-tab-list {
  --tab-height: 40px;
  --tab-padding-x: 8px;
  --tab-inner-padding-x: 12px;
  --tab-inner-padding-y: 10px;
  --tab-inner-border-radius: 0;
  --tab-hover-background: rgba(0, 0, 0, 0.04);
  --tab-focus-visible-background: rgba(0, 0, 0, 0.07);
  --tab-color-active: var(--text-primary);
  --tab-font-size: 18px;
  --tab-font-family: "Bebas Neue Pro SemiExpanded";
  box-shadow: inset 0 -1px 0 0 var(--tab-hover-background);
  font-size: var(--tab-font-size);
  font-family: var(--tab-font-family);
  line-height: 1;
  letter-spacing: 0em;

  .base-tab-list-trigger.underline:where(
      [data-state="active"],
      [data-active]
    )::before {
    box-sizing: border-box;
    content: "";
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--text-primary);
  }
}

.base-tab-list-trigger {
  font-size: var(--tab-font-size);
  font-family: var(--tab-font-family);
  color: var(--tab-color-active);
  transition: opacity 0.2s ease-in-out;

  @media (hover: hover) {
    &:where(:hover) {
      color: var(--tab-color-active);
      opacity: 0.75 !important;
    }
    &:where(:hover) :where(.base-tab-list-trigger-inner) {
      background-color: var(--tab-hover-background);
    }
    &:where(:focus-visible:hover) :where(.base-tab-list-trigger-inner) {
      background-color: var(--tab-focus-visible-background);
    }
  }
  &:where([data-state="active"], [data-active]) {
    color: var(--tab-color-active);
  }
  &:where([data-state="inactive"], [data-inactive]) {
    opacity: 0.33;
  }
  &:where(:focus-visible) :where(.base-tab-list-trigger-inner) {
    outline: var(--focus-outline);
    outline-offset: -2px;
  }
}
