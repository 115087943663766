@import "_variables.scss";

// centering
@mixin flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

// centering
@mixin grid-centered {
  display: grid;
  align-items: center;
  justify-content: center;
}

// dashed border
@mixin border-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

// Small tablets and large smartphones (landscape view)
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Small tablets (portrait view)
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Tablets and small desktops
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Large tablets and desktops
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// Extra large tablets and desktops
@mixin xxl {
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}
