@import "../../../assets/scss/variables";

.tooltip-wrapper {
  font-family: "Poppins";
  font-weight: 400;
  .tooltip-text {
    margin: 0;
    display: flex;
    align-items: center;
    font-family: "Poppins";
    font-weight: 400;
  }
  .children {
    font-family: "Bebas Neue Pro SemiExpanded Bold";
  }
  div {
    font-family: "Poppins";
    font-weight: 400;
  }
  .tooltip {
    background-color: var(--tooltip-bg);
    border: 1px solid var(--background-inverted);
    color: var(--text-primary);
    border-radius: 0px;
    padding: 15px 12px;
    font-family: "Poppins";
    font-size: $fontXSmall;
    max-width: 280px;
    font-family: "Poppins";
    font-weight: 400;
  }
  img {
    display: flex;
    align-items: center;
  }
}

.icon {
  filter: invert(1);
}
