@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.modal-screen {
  width: 420px;
  height: 388px;
  @include flex-centered;

  .modal-label {
    justify-self: flex-start;
    align-self: flex-start;
    width: 100%;
    color: var(--text-primary);
    align-self: flex-start;
    font-family: "Bebas Neue Pro SemiExpanded Bold";
    font-size: 24px;
    margin: 0;
  }

  .btn {
    width: 100%;
    height: 3rem;
    padding: 1rem 1.3rem;
    margin-top: -1rem;
  }

  input,
  select {
    padding: 0.5rem 1rem;
    border: 1px solid #717070;
    border-radius: 16px;
    height: 2.7rem;
    width: 100%;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .delete-wrapper {
    display: flex;
    flex-direction: column;
    @include flex-centered;
    margin-bottom: 2.5rem;
    gap: 1rem;
    text-align: center;
    font-family: "Poppins Regular";
    font-weight: $weightNormal;
    font-size: $fontSmall;

    .icon {
      width: 4rem;
      height: 4rem;
    }

    .modal-desc {
      color: var(--text-primary);
      width: 80%;
      margin: 0;
    }
  }
}
