@import "./assets/scss/variables";
@import "./assets/scss/fonts";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700,800");

.AppWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.AppMain {
  flex: 1;
  margin-top: 88px;
  background: var(--background);
  width: 100%;
  display: flex;
  flex-direction: column;
}
:global {
  [data-theme="light"] {
    --background: #{$white};
    --background-washed: #{$whiteWashed2};
    --background-washed-2: #{$whiteWashed};
    --background-inverted: #{$black};
    --text-primary: #{$black};
    --text-primary-inverted: #{$white};
    --text-secondary: grey;
    --text-danger: #{$danger};
    --user-group-bg: #{$whiteWashed2};
    --user-profile-bg: #{$black};
    --user-profile-text: #{$white};
    --state-hover: color-mix(in srgb, var(--background) 90%, #000 10%);
    --state-hover-inverted: color-mix(
      in srgb,
      var(--background-inverted) 80%,
      #fff 20%
    );
    --state-active: #d5d5d5;
    --state-disabled-background: color-mix(
      in srgb,
      var(--background) 66%,
      #000 33%
    );
    --state-disabled-background-inverted: color-mix(
      in srgb,
      var(--background-inverted) 50%,
      #fff 50%
    );
    --focus-outline: 2px solid #002bd86e;
    --popup-success-description: #3c3c3c;
    --popup-mint-preview-desc: #717070;
    --popup-background: rgba(148, 148, 148, 0.8);
    --popup-shadow: 4px 4px 0px #{$black};

    // tooltip specific
    --tooltip-bg: #{$whiteWashed3};

    transition: all 750ms;
  }
  [data-theme="dark"] {
    --background: #{$black};
    --background-washed: #{$blackWashed};
    --background-washed-2: #{$blackWashed2};
    --background-inverted: #{$white};
    --text-primary: #{$white};
    --text-primary-inverted: #{$black};
    --text-secondary: grey;
    --text-danger: #{$danger};
    --user-group-bg: #131313;
    --user-profile-bg: #{$white};
    --user-profile-text: #{$black};
    --state-hover: color-mix(in srgb, var(--background) 90%, #fff 10%);
    --state-hover-inverted: color-mix(
      in srgb,
      var(--background-inverted) 80%,
      #000 20%
    );
    --state-active: #3c3c3c;
    --state-disabled-background: color-mix(
      in srgb,
      var(--background) 66%,
      #fff 33%
    );
    --state-disabled-background-inverted: color-mix(
      in srgb,
      var(--background-inverted) 50%,
      #000 50%
    );

    // popups specfic
    --popup-success-description: #949494;
    --popup-mint-preview-desc: #949494;
    --popup-background: rgba(29, 29, 29, 0.9);
    --popup-shadow: 4px 4px 0px #{$white};

    // tooltip specific
    --tooltip-bg: #{$blackWashed2};

    transition: all 750ms;
  }

  html {
    height: 100%;
  }

  html,
  body {
    max-width: 100vw;
    min-height: 100%;
    margin: 0;
  }

  div#root {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  *::-webkit-scrollbar {
    width: 0rem;
    display: none;
    -webkit-appearance: none;
  }

  body {
    font-family:
      Bebas Neue Pro,
      sans-serif;
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-size: 16px;
    background-color: rgb(255, 255, 255);
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  button {
    // Resets
    -webkit-appearance: none;
    border-radius: 0;
    text-align: inherit;
    background: none;
    box-shadow: none;
    padding: 0;
    cursor: pointer;
    border: none;
    color: inherit;
    font: inherit;
  }

  [tabindex] {
    outline: none;
    // width: 100%;
    height: 100%;
  }

  .react-datepicker {
    border-radius: 0;
  }
  .react-datepicker__day {
    border-radius: 0;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: black;
  }
  .react-datepicker__day:hover {
    border-radius: 0;
  }

  .react-datepicker__close-icon {
    margin-right: 1rem !important;
    padding-right: 0 !important;
    height: auto !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-decoration: none;
    margin: 0;
    padding: 0;
    margin: 0.7em 0;
  }

  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 50px;
  }
  h3 {
    font-size: 40px;
  }
  h4 {
    font-size: 32px;
  }
  h5 {
    font-size: 24px;
  }
  h6 {
    font-size: 20px;
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    color: inherit;
    outline: none;
  }

  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box !important;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

@font-face {
  font-family: "Porsche Next";
  src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-la-regular.min.b8f1c203bd2ac5334247025236ba8d86.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
