.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-label {
  font-family: "Bebas Neue Pro SemiExpanded Bold";
  font-size: 24px;
  margin-bottom: 24px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: black;

  &.dark {
    color: white;
  }
}
