.toast-wrapper {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 0.7s ease 0s normal 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #ffd8dd;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999;
  box-shadow: 4px 4px 0px #000000;
  pointer-events: none;

  &.success {
    background-color: rgb(84, 209, 146);
  }
}

.title {
  font-family: "Poppins SemiBold";
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
  color: #ea1548;
}

.msg {
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  color: #4b0213;
}
