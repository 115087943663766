@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.offer-wrapper {
  display: flex;
  background-color: var(--background-washed);
  width: 100%;
  gap: 24px;
  padding: 1rem 0;

  .img-container {
    display: flex;
    justify-items: center;
    gap: 8px;

    .img-main {
      width: 224px;
      height: 224px;
      background-color: $color-moderate-grey-light;
      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        cursor: pointer;
        object-fit: cover;
      }
    }
    .img-secondary {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .img-small {
        width: 108px;
        height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: $color-moderate-grey-light;
        overflow: hidden;

        img {
          width: 108px;
          height: 108px;
          cursor: pointer;
        }
      }
    }

    .edit {
      cursor: pointer;
    }
  }

  .info-container {
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      max-width: 70ch;
      margin: 0rem;
      cursor: pointer;
    }

    .desc-div {
      display: flex;
      flex-direction: column;
      text-align: start;

      .name {
        font-size: $fontMedium;
        font-weight: $weightSemiBold;
        margin-bottom: 8px;
      }

      .desc {
        color: var(--popup-success-description);
        margin-bottom: 24px;
        font-family: "Poppins Regular";
        font-size: $fontXSmall;
      }
    }

    .stat-div {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      font-size: $font-tiny;
      gap: 4px;
      font-family: "Poppins";
      font-size: $fontXSmall;

      .key {
        color: $color-disabled;
        display: flex;
        align-items: center;
        gap: 2px;
        color: #717070;

        b {
          color: var(--text-primary);
          margin-left: 2px;
        }
      }
    }

    .nav-div {
      display: flex;
      justify-self: flex-end;
      align-items: center;
      // justify-content: space-between;
      grid-row: 3/4;
      font-size: $font-normal;
      font-weight: $font-weight-bolded;
      gap: 1rem;
      font-family: "Bebas Neue Pro SemiExpanded Bold";
      font-size: $fontMedium;
      font-weight: $weightBold;
      p {
        display: flex;
        align-items: center;
        gap: 0.2rem;
      }

      .status-label {
        position: relative;
      }

      .expand-icon {
        transition: transform 0.2s;
        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    .button-wrapper {
      display: flex;
      position: relative;

      .button {
        gap: 1rem;
        font-size: $fontMedium;
        font-weight: 700;
      }

      .tooltip-text {
        margin: 0;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-family: "Poppins";
      }

      .tooltip {
        background-color: var(--tooltip-bg);
        border: 1px solid var(--background-inverted);
        color: var(--text-primary);
        border-radius: 0px;
        padding: 15px 12px;
        font-family: "Poppins";
        font-size: $fontXSmall;
        font-weight: 400;
        max-width: 280px;
      }
    }
  }
}

.status-menu {
  display: flex;
  flex-direction: column;
  @include flex-centered;
  position: absolute;
  width: 6rem;
  gap: 0.5rem;
  padding: 0.5rem;
  background: var(--background);
  border: 1.4px solid var(--background-inverted);
  top: 1.5rem;
  z-index: 3;

  .offer-status-col-draft {
    @include flex-centered;
    position: relative;
    border: 1px solid $color-disabled;
    padding: 0.02rem;
    border-radius: 4px;
    padding: 0.12rem 0.7rem;
    width: 100%;

    img {
      position: absolute;
      left: 0.2rem;
    }

    p {
      margin: 0;
      font-weight: $font-weight-bolded;
      font-size: $font-tiny;
      color: $color-disabled;
    }
  }

  .offer-status-col-active {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #29bc63;
    color: $white;
    padding: 10px, 4px, 10px, 4px;
    width: 68px;
    height: 20px;
    gap: 5px;

    p {
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }

  .offer-status-col-inactive {
    @include flex-centered;
    position: relative;
    width: 70%;
    background: #ffa053;
    color: $white;
    padding: 10px, 4px, 10px, 4px;
    width: 68px;
    height: 20px;
    gap: 5px;

    p {
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }
}

.modal-screen {
  width: 37rem;
  display: flex;
  overflow: hidden;
}
