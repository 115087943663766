@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.popup-wrapper {
  --modal-padding: 20px;
  --modal-padding-body: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--popup-background);
  position: relative;
  z-index: 1000;

  .content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--background);

    .header {
      padding-inline: var(--modal-padding);
      padding-block: var(--modal-padding);
      position: sticky;
      top: 0;
      width: 100%;
      background: var(--background);
      z-index: 1;

      &.with-divider {
        border-bottom: 1px solid var(--background-washed);
      }

      .title {
        margin: 0;
        padding-inline-end: 1.4rem;
        max-width: 40ch;
      }
      .close-btn {
        position: absolute;
        right: var(--modal-padding);
        top: var(--modal-padding);
        z-index: 1;
        opacity: 0.7;
        transition: opacity 0.3s;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }

        & > img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .body {
      padding-inline: var(--modal-padding);
      padding-block: var(--modal-padding-body) var(--modal-padding);
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      background: var(--background);

      &.contrasted {
        background: color-mix(
          in srgb,
          var(--background) 98%,
          var(--background-inverted) 2%
        );
      }
    }
  }
}
