@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: $white;
  padding: 10px 4px 10px 4px;
  min-width: 2em;
  height: 20px;
  gap: 5px;
  font-family: "Poppins Semibold";
  font-weight: 600;
  font-size: 10px;
  user-select: none;

  img {
    height: 15px;
  }

  &[data-variant="default"] {
    background: var(--text-secondary);
  }
  &[data-variant="info"] {
    background: #08a2df;
  }
  &[data-variant="success"] {
    background: #29bc63;
  }
  &[data-variant="warning"] {
    background: #ffa053;
  }
  &[data-variant="danger"] {
    background: #df0808;
  }
}
