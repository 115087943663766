// Colors
$white: #ffffff;
$whiteWashed: #dedede;
$whiteWashed2: #f3f3f3;
$whiteWashed3: #f9f9f9;
$black: #000000;
$blackWashed: #131313;
$blackWashed2: #1d1d1d;
$grey: #949494;
$lightGrey: #d5d5d5;
$danger: #f94242;

$color-dark-grey: #1d1d1d;
$color-disabled: #717070;
$color-moderate-grey-darker: #151616;
$color-moderate-grey: #292a2a;
$color-moderate-grey-light: #3c3c3c;
$color-light-grey: #949494;
$color-neutral-15: #f9f9f9;

// font-families
$bebasRegular: "Bebas Neue Pro";
$bebasSemiExpanded: "Bebas Neue Pro SemiExpanded";
$bebasExpanded: "Bebas Neue Pro SemiExpanded";

// font-sizes
$fontTiny: 10px;
$fontXSmall: 12px;
$fontSmall: 14px;
$fontMedium: 16px;
$fontLarge: 18px;
$fontXLarge: 20px;

$font-tiny: 0.85rem;
$font-normal: 1rem;
$font-big: 1.1rem;
$font-giant: 1.6rem;
$font-h1: 2rem;
$font-12: 12px;

// font-weights
$weightNormal: 400;
$weightMedium: 500;
$weightSemiBold: 600;
$weightBold: 700;
$weightExtraBold: 800;
$font-weight-bolded: 600;

// Maximum width of sections
$maxSectionWidth: 1440px;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;

// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 992px;

// Large tablets and desktops
$screen-xl-max: 1230px;

// Extra large desktops
$screen-xxl-max: 1440px;
