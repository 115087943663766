@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.form-wrapper {
  color: var(--text-primary);
  background-color: var(--background);
  border: 1px solid var(--background-washed-2);
  padding: 20px;
}

.variant-label {
  justify-self: flex-start;
  align-self: flex-start;
  width: 100%;
  color: var(--text-primary);
  font-family: "Bebas Neue Pro SemiExpanded Bold";
  font-size: 24px;
  align-self: flex-start;
  margin: 0;
  margin-bottom: 32px;
}

.input {
  padding: 0.5rem 1rem;
  height: 2.7rem;
  width: 100%;
  -webkit-appearance: none;
  height: 48px;
  font-family: "Poppins Regular";
  font-size: $fontSmall;
  font-weight: $weightNormal;
  color: var(--text-primary);
}

.footer {
  align-items: center;
  @include flex-centered;
  justify-content: space-between;
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
  color: var(--text-primary);
}

.back-wrapper {
  align-items: center;
  color: var(--text-primary);
  @include flex-centered;
  gap: 0.5rem;
  cursor: pointer;

  span {
    font-size: $fontXLarge;
    font-weight: $weightBold;
    font-family: "Bebas Neue Pro SemiExpanded Bold";
  }
}

.arrow {
  cursor: pointer;
}

.content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .close-icon {
    width: 24px;
    height: 24px;
    right: 0;
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
  }
}

.images-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;

  .images-container {
    margin-top: 0.6rem;
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;

    .images-scroll {
      gap: 1rem;
      height: 100%;
      display: flex;
      position: relative;
      padding: 0 2rem;

      .scroll-arrow-prev {
        height: 100%;
        @include flex-centered;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        left: 0.7rem;
        z-index: 500;
        width: 3.5rem;
        // background:orange;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 38%,
          rgba(255, 255, 255, 0.887) 60%,
          rgba(255, 255, 255, 0.719) 74%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .scroll-arrow-next {
        height: 100%;
        @include flex-centered;
        align-items: flex-end;
        flex-direction: column;
        position: absolute;
        right: 0.8rem;
        z-index: 500;
        width: 3.5rem;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.4220281862745098) 18%,
          rgba(255, 255, 255, 0.6657256652661064) 28%,
          rgba(255, 255, 255, 0.8449973739495799) 37%,
          rgba(255, 255, 255, 1) 51%
        );
      }
    }

    .image-box {
      @include flex-centered;
      flex-direction: column;
      width: 98px;
      height: 98px;
      background: #3c3c3c;
      position: relative;
      overflow: hidden;

      img:nth-child(1) {
        min-width: 98px;
        min-height: 98px;
        object-fit: cover;
      }

      .nft {
        position: absolute;
        top: 0.15rem;
        left: 0.15rem;
        background-color: $black;
        color: $white;
        font-family: "Poppins SemiBold";
        font-size: $fontSmall;
        font-weight: $weightSemiBold;
        padding: 11px, 8px, 10px, 8px;
        width: 32px;
        height: 16px;
        @include flex-centered;
      }

      .close-icon {
        position: absolute;
        top: 0.15rem;
        right: 0.15rem;
        background-color: #1d1d1d;
        border-radius: 50%;
        padding: 0.2rem;
        cursor: pointer;
        // display: none;
      }
    }

    .add-box {
      font-size: 2rem;
      @include flex-centered;
      flex-direction: column;
      width: 98px;
      height: 98px;
      position: relative;
      width: 100%;
      // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border: 1px dashed var(--background-inverted);
      cursor: pointer;
      overflow: hidden;
      div {
        border: unset !important;
      }
      .add {
        position: absolute;
        cursor: pointer;
        @include flex-centered;
        flex-direction: column;

        p {
          font-family: "Poppins Regular";
          font-size: $fontTiny;
          font-weight: $weightNormal;
          margin: 0;
          color: $color-disabled;

          &:first-child {
            font-size: 24px;
            margin-bottom: -0.5rem;
          }
        }
      }

      .files-input {
        opacity: 0;
        width: 12rem;
        height: 15rem;
        cursor: pointer;
      }
    }
  }
}

.label-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  color: black;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: "Poppins";
  margin-bottom: 8px;
}

.file-name-label {
  color: black;
}

.description {
  font-family: "Poppins";
  font-size: $fontSmall;
  color: var(--text-secondary);
  margin: 0 0 8px;
}
