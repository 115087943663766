.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background);
  width: 100%;
  height: 100%;
  transition: all 750ms;
  padding: 24px;
}
