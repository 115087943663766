@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.tab-label {
  align-self: center;
  margin: 0;
  background-color: $color-moderate-grey;
  padding: 0.5rem 1rem;
  &:hover {
    cursor: pointer;
  }
}

.err-msg {
  color: red;
}

.form {
  height: 40rem;
  width: 100%;
  gap: 1rem;
  padding: 0.5rem 0;
  overflow-y: scroll;

  .scrollable {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 6rem;
    border-bottom: 1px solid var(--background-washed);
  }

  .images-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    // align-products: flex-start;

    .label-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .images-container {
      width: 100%;
      margin-top: 0.6rem;
      display: flex;
      gap: 0.5rem;
      overflow: hidden;

      .images-scroll {
        gap: 1rem;
        height: 100%;
        width: 81%;
        display: flex;
        position: relative;
        margin-left: 1rem;
        overflow: hidden;

        .scroll-arrow-prev {
          height: 100%;
          @include flex-centered;
          align-items: flex-start;
          flex-direction: column;
          position: absolute;
          z-index: 500;
          width: 3.5rem;
          // background:orange;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 38%,
            rgba(255, 255, 255, 0.887) 60%,
            rgba(255, 255, 255, 0.719) 74%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .scroll-arrow-next {
          height: 100%;
          @include flex-centered;
          align-items: flex-end;
          flex-direction: column;
          position: absolute;
          right: 0rem;
          z-index: 500;
          width: 3.5rem;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4220281862745098) 18%,
            rgba(255, 255, 255, 0.6657256652661064) 28%,
            rgba(255, 255, 255, 0.8449973739495799) 37%,
            rgba(255, 255, 255, 1) 51%
          );
        }
      }

      .image-box {
        @include flex-centered;
        flex-direction: column;
        width: 98px;
        height: 98px;
        background: #3c3c3c;
        position: relative;
        overflow: hidden;

        img:nth-child(1) {
          min-width: 98px;
          min-height: 98px;
          object-fit: cover;
        }

        .nft {
          position: absolute;
          top: 0.15rem;
          left: 0.15rem;
          background-color: $black;
          color: $white;
          font-family: "Poppins SemiBold";
          font-size: $fontSmall;
          font-weight: $weightSemiBold;
          padding: 11px, 8px, 10px, 8px;
          width: 32px;
          height: 16px;
          @include flex-centered;
        }

        .close-icon {
          position: absolute;
          top: 0.2rem;
          right: 0.1rem;
          background-color: #1d1d1d;
          border-radius: 50%;
          right: 0.15rem;
          padding: 0.2rem;
          cursor: pointer;
          display: none;
        }
      }

      .add-box {
        font-size: 2rem;
        @include flex-centered;
        flex-direction: column;
        width: 98px;
        height: 98px;
        position: relative;
        border: 1px dashed var(--background-inverted);
        cursor: pointer;
        overflow: hidden;
        color: var(--text-primary);
        div {
          border: unset !important;
          background: var(--background);
        }
        .add {
          position: absolute;
          cursor: pointer;
          color: var(--text-primary);
          @include flex-centered;
          flex-direction: column;

          p {
            font-family: "Poppins Regular";
            font-size: $fontTiny;
            font-weight: $weightNormal;
            margin: 0;
            color: $color-disabled;

            &:first-child {
              font-size: 24px;
              margin-bottom: -0.5rem;
            }
          }
        }

        .files-input {
          opacity: 0;
          width: 12rem;
          height: 15rem;
          cursor: pointer;
          color: var(--text-primary);
          p {
            color: var(--text-primary);
          }
        }
      }
    }
  }

  .btn {
    align-self: flex-end;
  }

  .disabled {
    background: $color-disabled;
    color: white;
    opacity: 0.4;
  }

  .input-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 2rem;
    row-gap: 0.5rem;

    .input-error {
      border-color: red;
    }
  }

  .input {
    padding: 0.5rem 1rem;
    height: 2.35rem;
    width: 100%;
    color: var(--text-primary);
    -webkit-appearance: none;
    height: 48px;
    font-size: 14px;
    font-family: "Poppins Regular";
    font-weight: $weightNormal;
    font-size: $fontSmall;
    &:focus {
      border: 1.5px solid var(--background-inverted);
    }
  }
  textarea {
    resize: none;
    height: 5rem !important;
    color: var(--text-primary);
  }

  .desc-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
    color: $black;
    font-family: "Poppins Semibold";
    font-size: $fontXSmall;

    .label {
      display: flex;
      align-items: center;
      margin: 0;
      div {
        margin-left: 2px;
      }
    }
  }
}

.promo-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 0.5rem;

  div:first-child {
    display: flex;
    align-items: center;
    img {
      margin-left: 4px;
    }
  }
  .label-wrapper {
    img {
      margin-right: 1rem;
    }
  }
  .add-box {
    font-size: 2rem;
    @include flex-centered;
    flex-direction: column;
    width: 17rem;
    height: 6.7rem;
    position: relative;
    // @include border-dashed;
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border: 1px dashed var(--background-inverted);
    cursor: pointer;
    overflow: hidden;
    margin-top: 0.5rem;
    div {
      border: unset;
      background: var(--background);
    }
    .close-icon {
      position: absolute;
      top: 0.15rem;
      right: 0.15rem;
      background-color: #1d1d1d;
      border-radius: 50%;
      padding: 0.2rem;
      cursor: pointer;
      display: none;
    }

    .add {
      position: absolute;
      cursor: pointer;
      text-align: center;
      @include flex-centered;
      flex-direction: column;
      height: 100%;
      color: var(--text-primary);

      p {
        font-family: "Poppins Regular";
        font-size: $fontTiny;
        font-weight: $weightNormal;
        margin: 0;
        color: $color-disabled;

        &:first-child {
          font-size: 24px;
          margin-bottom: -0.5rem;
        }
      }

      img {
        width: 230%;
        position: absolute;
      }
    }

    .disabled-input {
      p {
        color: $color-disabled;
        // opacity: 0.4;
      }
    }

    .files-input {
      display: flex;
      height: 100%;
      cursor: pointer;
      // z-index: 1;
      color: $white;
      border: none;
      position: absolute;
      bottom: 0;
      opacity: 0;
    }
  }
}

.success-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  height: 407px;
  width: 100%;

  img {
    margin-top: 48px;
  }

  .label {
    font-size: $font-h1;
    margin-top: 24px;
    margin-bottom: 8px;
    font-family: "Bebas Neue Pro SemiExpanded Bold";
    font-size: 24px;
  }

  .msg {
    text-align: center;
    font-family: "Poppins";
    color: var(--popup-success-description);
    font-size: $fontSmall;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .btn {
    align-self: center;
    padding: 0 1rem;
    padding: 1rem 1.3rem;
    margin-bottom: 1rem;
  }

  .program-page {
    display: flex;
    font-weight: $font-weight-bolded;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      margin: 0;
      margin-left: 4px;
    }
    p {
      font-family: "Bebas Neue Pro SemiExpanded Bold";
      font-size: $fontXLarge;
      margin: 0;
    }
  }
}

.no-rules-message {
  color: black;
}
