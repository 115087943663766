@import "../../../assets/scss/variables";

.body {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  padding-block-start: 3rem;
}

.qr {
  width: 200px;
  height: 200px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
