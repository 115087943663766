@import "../../assets/scss/variables";
@import "../../assets/scss/fonts";

.header-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 88px;
  position: fixed;
  top: 0;
  z-index: 999;
  background: var(--background);

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    background: var(--background);
    height: 88px;
    padding: 10px 40px;
    .nav-label-container {
      padding: 0 12px;
      color: var(--text-primary);
    }

    .spacer {
      flex: 1;
    }
    .separator {
      border-left: 1px solid var(--header-separator-bg);
      height: 24px;
      margin: 0 12px;
    }
    .theme-mode-button-light {
      height: 20px;
      width: 20px;
      background: url("../../assets/icons/dark-mode-icon.svg");
      &:hover {
        background: url("../../assets/icons/dark-mode-icon-hover.svg");
        cursor: pointer;
      }
      &:active {
        background: url("../../assets/icons/dark-mode-icon-active.svg");
        cursor: pointer;
      }
    }
    .theme-mode-button-dark {
      height: 20px;
      width: 20px;
      background: url("../../assets/icons/light-mode-icon.svg");
      &:hover {
        background: url("../../assets/icons/light-mode-icon-hover.svg");
        cursor: pointer;
      }
      &:active {
        background: url("../../assets/icons/light-mode-icon-active.svg");
        cursor: pointer;
      }
    }
    .user-group-wrapper {
      padding-left: 12px;
      a {
        margin: 0;
      }
      .user-group {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        padding: 10px 10px 10px 24px;
        font-size: $fontSmall;
        background-color: var(--user-group-bg);
        color: var(--text-primary);
        .balance-group {
          display: flex;
          align-items: center;
          font-family: "Poppins";
          .balance {
            margin-right: 8px;
            font-weight: $weightMedium;
          }
          img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
          .amount {
            font-weight: 600;
            white-space: nowrap;
            margin-right: 24px;
          }
        }
        .user-profile {
          display: flex;
          align-items: center;
          padding: 8px 12px 8px 8px;
          background-color: var(--user-profile-bg);
          white-space: nowrap;
          color: var(--user-profile-text);
          font-size: $fontXLarge;
          font-family: "Bebas Neue Pro SemiExpanded Bold";
          img {
            margin-right: 4px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .admin-group-wrapper {
      display: flex;
      align-items: center;
    }
    .logout-button {
      margin: 0 12px;
      color: var(--text-primary);
      background-color: unset;
      font-weight: $weightExtraBold;
      font-family: "Porsche Next";
      font-size: $fontXLarge;
      white-space: nowrap;
      padding: 0;
      &:hover {
        cursor: pointer;
      }
    }
    .register-button {
      font-family: $bebasSemiExpanded;
      font-size: $fontXLarge;
      font-weight: $weightBold;
      padding: 0 12px;
      border: unset;
      padding: 10px 16px;
      margin-left: 12px;
      height: 40px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
[data-theme="light"] {
  // header specific
  --header-separator-bg: #{$lightGrey};
  transition: all 750ms;
}
[data-theme="dark"] {
  // header specific
  --header-separator-bg: #{$color-moderate-grey-light};
  transition: all 750ms;
}

.logo-wrapper {
  &:not(.active-app-settings) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  &.active-app-settings {
    display: flex;
    justify-content: center;
    position: relative;
  }

  &:hover {
    cursor: pointer;
  }
}

.menu-wrapper {
  margin-left: auto;

  &.active-app-settings {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  grid-column: 3;
  a {
    margin: 0;
  }
}

.logo {
  font-size: 40px;
  font-family: "Bebas Neue Pro";
  font-weight: 700;
  transition: all 750ms;
  color: black;
  display: flex;
  align-items: center;
  height: 16px;

  &.dark {
    color: white;
  }

  span {
    color: var(--text-secondary);
    padding-inline-start: 0.2em;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
