@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.offer-wrapper {
  display: flex;
  background-color: var(--background-washed);
  width: 100%;
  gap: 24px;
  padding: 1rem 0;

  .img-container {
    display: flex;
    justify-items: center;
    gap: 8px;

    .img-main {
      width: 300px;
      aspect-ratio: 1/1;

      background-color: $color-moderate-grey-light;
      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      img {
        width: 300px;
        aspect-ratio: 1/1;
        object-fit: contain;
        cursor: pointer;
      }
    }
    .img-secondary {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .img-small {
        width: 108px;
        height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: $color-moderate-grey-light;
        overflow: hidden;

        img {
          width: 108px;
          height: 108px;
          cursor: pointer;
        }
      }

      // .img-small:nth-child(2) {
      //   background-color: inherit;
      //   color: $grey;
      //   width: 108px;
      //   height: 108px;
      //   border: 1px solid $grey;

      //   div {
      //     color: $grey;
      //     font-family: "Poppins Regular";
      //     font-size: 12px;
      //   }
      //   img {
      //     width: 108px;
      //     height: 108px;
      //   }
      // }
    }

    .edit {
      cursor: pointer;
    }
  }

  .info-container {
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin: 0rem;
      cursor: pointer;
    }

    .desc-div {
      display: flex;
      flex-direction: column;
      text-align: start;

      .name {
        font-size: $fontMedium;
        font-weight: $weightSemiBold;
        margin-bottom: 8px;
      }

      .desc {
        color: var(--popup-success-description);
        margin-bottom: 24px;
        font-family: "Poppins Regular";
        font-size: $fontXSmall;
      }
    }

    .stat-div {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      font-size: $font-tiny;
      gap: 4px;
      font-family: "Poppins";
      font-size: $fontXSmall;

      .key {
        color: $color-disabled;
        display: flex;
        align-items: center;
        gap: 2px;
        color: #717070;

        b {
          color: var(--text-primary);
          margin-left: 2px;
        }
      }
    }

    .nav-div {
      display: flex;
      justify-self: flex-end;
      align-items: center;
      // justify-content: space-between;
      grid-row: 3/4;
      font-size: $font-normal;
      font-weight: $font-weight-bolded;
      gap: 1.9rem;
      font-family: "Bebas Neue Pro SemiExpanded Bold";
      font-size: $fontMedium;
      font-weight: $weightBold;
      p {
        display: flex;
        align-items: center;
        gap: 0.2rem;
      }

      a {
        padding: 0.2rem 0;
        border-right: 1px solid $grey;
        padding-right: 1.5rem;
      }

      p:nth-child(4) {
        color: rgb(255, 0, 0);
      }
    }

    .buttonWrapper {
      display: flex;
      position: relative;

      .button {
        cursor: pointer;
        font-size: $font-normal;
        font-weight: $font-weight-bolded;
        gap: 1.9rem;
        font-family: "Bebas Neue Pro SemiExpanded Bold";
        font-size: $fontMedium;
        font-weight: $weightBold;
      }

      .button:disabled {
        cursor: not-allowed;
      }

      .tooltip-text {
        margin: 0;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-family: "Poppins";
      }

      .tooltip {
        background-color: var(--tooltip-bg);
        border: 1px solid var(--background-inverted);
        color: var(--text-primary);
        border-radius: 0px;
        padding: 15px 12px;
        font-family: "Poppins";
        font-size: $fontXSmall;
        font-weight: 400;
        max-width: 280px;
      }
    }
  }

  .note-input-wrapper {
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    word-wrap: break-word;
    width: 25%;
    .setted-note {
      display: flex;
      gap: 0.5rem;
    }
    .icon-wrapper {
      align-self: center;
      cursor: pointer;
    }
    p {
      white-space: pre-wrap;
    }
  }
}

textarea {
  resize: none;
  font-family: "Poppins Regular";
  font-size: $fontSmall;
  font-weight: $weightNormal;
}

.status-menu {
  display: flex;
  flex-direction: column;
  @include flex-centered;
  position: absolute;
  width: 6rem;
  gap: 0.5rem;
  padding: 0.5rem;
  background: var(--background);
  border: 1.4px solid var(--background-inverted);
  top: 1.5rem;

  .offer-status-col-draft {
    @include flex-centered;
    position: relative;
    border: 1px solid $color-disabled;
    padding: 0.02rem;
    border-radius: 4px;
    padding: 0.12rem 0.7rem;
    width: 100%;

    img {
      position: absolute;
      left: 0.2rem;
    }

    p {
      margin: 0;
      font-weight: $font-weight-bolded;
      font-size: $font-tiny;
      color: $color-disabled;
    }
  }

  .offer-status-col-active {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #29bc63;
    color: $white;
    padding: 10px, 4px, 10px, 4px;
    width: 68px;
    height: 20px;
    gap: 5px;

    p {
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }

  .offer-status-col-inactive {
    @include flex-centered;
    position: relative;
    width: 70%;
    background: #ffa053;
    color: $white;
    padding: 10px, 4px, 10px, 4px;
    width: 68px;
    height: 20px;
    gap: 5px;

    p {
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }
}

.modal-screen {
  width: 37rem;
  padding: 1rem;
  gap: 0.4rem;
  display: flex;
  overflow: hidden;
}

.modal-label {
  font-size: $fontXLarge;
  font-weight: $font-weight-bolded;
  justify-self: flex-start;
  align-self: flex-start;
  width: 100%;
  color: var(--text-primary);
  font-family: "Bebas Neue Pro SemiExpanded Bold";
  margin-top: -0.15rem;
  align-self: flex-start;
}

.status-label {
  position: relative;
}

.status-dropdown {
  z-index: 2;
  text-wrap: nowrap;
  width: auto;
  align-items: normal;
}
