@import "../../../assets/scss/variables";

.sort {
  display: flex;
  border: var(--sort-dropdown-border);
  color: var(--text-primary);
  min-width: 120px;
  position: relative;
  z-index: 2;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  &:hover {
    cursor: pointer;
  }
  .current-filter {
    display: flex;
    min-width: 120px;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    img {
      transform: rotate(90deg);
    }
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background);
    min-width: 120px;

    div {
      padding: 8px 16px;
      width: 100%;
      text-align: center;
      &:hover {
        cursor: pointer;
        background-color: var(--background-washed);
      }
    }
  }
}
