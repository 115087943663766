@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.table-style {
  display: flex;
  flex-direction: column;
  color: var(--loyalty-table-text);
  width: 100%;
  font-family: "Poppins";
  transition: all 750ms;

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }

  th {
    cursor: pointer;
    text-align: left;
    font-family: "Poppins SemiBold";
    font-size: $fontXSmall;
    padding: 0;
    color: var(--text-primary);
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      padding-right: 16px;
    }
  }

  tr {
    padding: 14px 16px;
    border-bottom: 1px solid var(--loyalty-table-border);
    font-size: $fontXSmall;
    transition: all 750ms;
  }

  tr:hover {
    background: var(--background-washed);
  }

  thead {
    tr:hover {
      background: none;
    }
  }

  td {
    font-family: "Poppins";
    font-size: $fontXSmall;
    padding: 16px 0;
    // width: 1rem;
    // max-width: 2rem;
    // line-height: 18px;
    font-size: $fontXSmall;
    &:first-child:not(:has(> img)) {
      padding-left: 16px;
    }
    &:last-child {
      padding-right: 16px;
    }
  }
  thead {
    font-size: $font-big;
    font-weight: $font-weight-bolded;
    width: 100%;
    height: 3rem;
    text-align: start;
  }

  tbody {
    width: 100%;

    tr {
      padding: 0px 16px;
      height: 48px;
      background-color: var(--background);

      td {
        padding: 0;

        &:has(> img) {
          max-width: 50%;
          aspect-ratio: 1/1;
          width: 72px;
          height: 72px;
          position: relative;

          & > img {
            position: absolute;
            inset: 8px 8px 8px 0;
            width: calc(100% - 2 * 8px);
            height: calc(100% - 2 * 8px);
            object-fit: contain;
          }
        }
      }
    }
    .expanded {
      background-color: var(--background-washed);
    }
  }

  .offer-status-col-draft {
    @include flex-centered;
    position: relative;
    border: 1px solid $color-disabled;
    padding: 10px 4px 10px 4px;
    width: 68px;
    height: 20px;
    gap: 5px;

    // img {
    //   position: absolute;

    // }

    p {
      margin: 0;
      font-weight: $font-weight-bolded;
      font-size: $font-tiny;
      color: $color-disabled;
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }

  .offer-status-col-active {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #29bc63;
    color: $white;
    padding: 10px 4px 10px 4px;
    width: 68px;
    height: 20px;
    gap: 5px;
    // img {
    //   margin-right: 2px;
    // }

    p {
      // margin: 0 0.2rem;
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }

  .offer-status-col-expired {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #df0808;
    color: $white;
    padding: 10px 4px 10px 4px;
    width: 68px;
    height: 20px;
    gap: 5px;
    // img {
    //   margin-right: 2px;
    // }

    p {
      // margin: 0 0.2rem;
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }

  .offer-status-col-upcoming {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #08a2df;
    color: $white;
    padding: 10px 4px 10px 4px;
    width: 68px;
    height: 20px;
    gap: 5px;
    // img {
    //   margin-right: 2px;
    // }

    p {
      // margin: 0 0.2rem;
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }

  .offer-status-col-inactive {
    @include flex-centered;
    position: relative;
    background: #ffa053;
    color: $white;
    padding: 10px 4px 10px 4px;
    width: 68px;
    height: 20px;
    gap: 5px;

    p {
      font-family: "Poppins Semibold";
      font-weight: 600;
      font-size: 10px;
    }
  }

  .pagination-wrapper {
    @include flex-centered;
    width: 100%;
    margin-top: 1rem;
    gap: 0.5rem;
    height: 100%;

    .page-box {
      color: var(--background-inverted);
      width: 2rem;
      height: 2rem;
      @include flex-centered;
      font-size: $font-big;
      border-radius: 8px;
      cursor: pointer;
      font-family: "Poppins Regular";
      font-size: 14px;
    }

    .active {
      background: var(--background);
      border: 1px solid var(--background-inverted);
      border-radius: 0;
    }

    .inactive {
      background: inherit;
      color: var(--text-primary);
    }

    .arrow {
      background-color: transparent;
      cursor: pointer;
    }
  }
}

.table-offers {
  th:nth-child(11) {
    text-align: right;
    color: $color-light-grey;
    font-family: "Poppins Regular";
    font-size: $fontXSmall;
    font-weight: $weightNormal;
  }

  td:nth-child(11) {
    width: 5%;
    text-align: right;
    padding-right: 1rem;
  }
}

.table-sales {
  th {
    text-align: left;
    font-family: "Poppins SemiBold";
    font-size: $fontXSmall;
    padding: 0;
    color: var(--text-primary);
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      text-align: right;
      color: $color-light-grey;
      font-family: "Poppins Regular";
      font-size: $fontXSmall;
      font-weight: $weightNormal;
    }
  }

  tr {
    padding: 14px 16px;
    border-bottom: 1px solid var(--loyalty-table-border);
    transition: all 750ms;
    // background: $whiteWashed2;
  }

  td {
    font-family: "Poppins";
    font-size: $fontXSmall;
    padding: 14px 0;
    max-width: 200px;
    // line-height: 18px;
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      padding-right: 16px;
    }
  }
}

.table-programs {
  th:nth-child(9) {
    text-align: right;
    color: $color-light-grey;
    font-family: "Poppins Regular";
    font-size: $fontXSmall;
    font-weight: $weightNormal;
  }

  td:nth-child(9) {
    text-align: right;
  }
}

.table-users {
  th:nth-child(8),
  td:nth-child(8) {
    text-align: right;
  }
}

.table-users-list {
  th:last-child {
    text-align: right;
    color: #949494;
    font-family: "Poppins Regular";
    font-size: 12px;
    font-weight: 400;
  }
}

.expanded {
  background-color: var(--background-washed);
}

[data-theme="light"] {
  // Loyalty Program
  --loyalty-table-text: #565656;
  --loyalty-table-border: #d5d5d5;

  transition: all 750ms;
}

[data-theme="dark"] {
  // Loyalty Program
  --loyalty-table-text: #949494;
  --loyalty-table-border: #1d1d1d;

  transition: all 750ms;
}

.items-length-text {
  margin: 2em 0;
  font-size: 10px;
  color: hsl(240 3.8% 46.1%);
}

.header-expander {
  cursor: default !important;
}
