.box-wrapper {
  color: var(--text-primary);
  background-color: var(--background);
  border: 1px solid var(--background-washed-2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .header-left-content {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;

      h6 {
        margin: 0;
      }
    }
    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;

      .action-btn {
        opacity: 0.7;
        .btn-icon {
          width: 1em;
          height: 1em;
        }
      }
    }
  }

  p {
    margin: 0 0 4px;
  }
}
